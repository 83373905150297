<template>
<a-modal
      :title="isNew?'创建部门':'编辑'"
      :visible="isShow"
      @ok="onOk"
      @cancel="onCancel"
      class="customermodel"
      width="500px"
      :centered="true"
    >
      <div class="editclasses-model">
        <a-form
          :form="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-form-item
              label="部门"
            >
            <a-select size="large" v-decorator="['grade',{rules: [{ required: true, message: '请选择部门!' }]}]">
              <template v-for="item in schoolGrades">
                <a-select-option :key="item" :value="item" v-if="item!='全部'">{{item}}</a-select-option>
              </template>
            </a-select>
          </a-form-item>
          <a-form-item label="子部门">
            <a-input size="large" v-decorator="['name',{rules: [{ required: true, message: '子部门不能为空!' }]}]"/>
          </a-form-item>
          <!-- <a-form-item
            label="负责的老师"
          >
            <a-select mode="tags" size="large" v-model="data.ids">
              <template v-for="item in teachers">
                <a-select-option :key="item._id" :value="item._id" >{{item.real_name}}</a-select-option>
              </template>
            </a-select>
          </a-form-item> -->
        </a-form>
      </div>
</a-modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import apis from '@/business/apis'
export default {
  inject: ['eventBus'],
  // props: {
  //   teachers: { default: () => { return [] } }
  // },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'edit_class' }),
      // data: {},
      oldData: {},
      isNew: true,
      isShow: false
    }
  },
  computed: {
    ...mapState('school', [
      'currentSchool'
    ]),
    ...mapGetters('school', [
      'schoolGrades'
    ])
  },
  methods: {
    ShowModal (isNew, data) {
      this.isNew = isNew
      this.isShow = true
      // this.data = JSON.parse(JSON.stringify(data))
      this.oldData = data
      this.initForm()
    },
    onOk () {
      this.form.validateFields((err, values) => {
        if (err) return
        this.submit(values)
      })
    },
    async submit (values) {
      const data = {
        name: values.name,
        grade: values.grade,
        school_id: this.currentSchool._id
        // teachers: this.data.ids
      }
      if (data.name !== this.oldData.name) {
        const res = await this.$http.post(apis.studentGroup.isExisted, data)
        if (res.body.existed) {
          this.$message.info('相同的部门名称已存在，不能重复！')
          return
        }
      }
      const index = this.schoolGrades.indexOf(data.grade)
      data.weight = 100 - index + 1
      if (this.isNew) {
        const response = await this.$http.post(apis.studentGroup.create, data)
        if (response.body.success) {
          this.$message.success('创建成功')
        }
      } else {
        const response = await this.$http.post(apis.studentGroup.update + this.oldData._id, data)
        if (response.body.success) {
          this.$message.success('修改成功')
        }
      }
      this.isShow = false
      this.$emit('confirmEdit')
    },
    onCancel () {
      this.isShow = false
    },
    initForm () {
      setTimeout(() => {
        const fields = {
          name: { value: this.oldData.name },
          grade: { value: this.oldData.grade }
        }
        this.form.setFields(fields)
      }, 0)
    }
  },
  created () {
    this.eventBus.$on('show-editclass-modal', this.ShowModal)
  },
  beforeDestroy () {
    this.eventBus.$off('show-editclass-modal', this.ShowModal)
  }
}
</script>
