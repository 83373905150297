<template>
  <div class="classes-manage">
    <div class="list">
      <div class="title">
        <h1>部门管理</h1>
        <div class="fun">
          <div class="button">
            <a-button
              type="primary"
              size="large"
              @click="addClass"
            >创建部门</a-button>
          </div>
        </div>
      </div>
      <div class="table">
        <a-table
          :columns="columns"
          :data-source="data"
          bordered
          class="custom-table"
          rowKey='_id'
          :pagination="pagination"
          @change="tableChange"
        >
          <template
            slot="operation"
            slot-scope="text, record"
          >
            <a @click="editClass(record)">编辑</a>
            <a-divider type="vertical" />
            <a
              @click="delClass(record)"
              :disabled="record.studentCount > 0"
            >删除</a>
          </template>
        </a-table>
      </div>
    </div>
    <edit-class @confirmEdit='loadData'></edit-class>
    <delete-confirm @confirmDelete='confirmDeleteFun'></delete-confirm>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import editClass from '@/components/systemManage/EditClass'
import deleteConfirm from '@/components/systemManage/DeleteConfirm'
import apis from '@/business/apis'
const columns = [
  {
    title: '部门',
    dataIndex: 'grade',
    width: 140
  },
  {
    title: '子部门',
    dataIndex: 'class',
    width: 140
  },
  {
    title: '负责人',
    dataIndex: 'names'
  },
  {
    title: '最后操作人',
    dataIndex: 'updated_by',
    width: 140
  },
  {
    title: '最后操作时间',
    dataIndex: 'update_time',
    width: 180
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    width: 120
  }
]
export default {
  inject: ['eventBus'],
  components: {
    editClass,
    deleteConfirm
  },
  data () {
    return {
      data: [],
      teachers: [],
      pagination: {
        current: 1
      },
      columns
    }
  },
  computed: {
    ...mapState('school', [
      'currentSchool'
    ]),
    ...mapGetters('user', [
      'isTeacher'
    ])
  },
  methods: {
    async loadData () {
      let query = '?schoolId=' + this.currentSchool._id + '&current=' + this.pagination.current
      const response = await this.$http.get(apis.studentGroup.list + query)
      query = '?schoolId=' + this.currentSchool._id + '&pageSize=100000' // + '&role=teacher'
      const teachers = await this.$http.get(apis.schoolAdmin.list + query)
      this.data = response.body.list
      this.pagination = response.body.pagination
      this.teachers = teachers.body.list
      // console.log(response.body)
      this.data.forEach(group => {
        group.class = group.name
        group.update_time = moment(group.updated_at).format('YYYY-MM-DD HH:mm')
        const list = this.teachers.filter(item => item.groups && item.groups.indexOf(group._id) !== -1)
        group.ids = []
        group.names = ''
        list.forEach((item, index) => {
          group.ids.push(item._id)
          group.names = index === 0 ? item.real_name : group.names + '、' + item.real_name
        })

        const counts = response.body.counts.filter(item => item._id === group._id)
        group.studentCount = counts[0] ? counts[0].total : 0
      })
    },
    tableChange (pagination, filters, sorter) {
      this.pagination.current = pagination.current
      this.loadData()
    },
    addClass () {
      this.eventBus.$emit('show-editclass-modal', true, {})
    },
    editClass (data) {
      this.eventBus.$emit('show-editclass-modal', false, data)
    },
    delClass (data) {
      this.eventBus.$emit('show-deleteconfirm-modal', data.class, data)
    },
    async confirmDeleteFun (data) {
      const response = await this.$http.post(apis.studentGroup.delete + data._id, { teachers: data.ids })
      if (response.body.success) {
        this.$message.success('删除成功')
        await this.loadData()
      }
    }
  },
  async created () {
    if (this.isTeacher) {
      this.$router.back()
      return
    }
    await this.loadData()
  }
}
</script>

<style lang="less">
.classes-manage {
  .list {
    border: 1px solid #e7e7eb;
    background: #fff;
    border-radius: 6px;
    padding: 0 16px;
    > .title {
      padding-top: 16px;
      display: flex;
      align-items: center;
      h1 {
        flex: 1;
        font-size: 20px;
      }
      .fun {
        display: flex;
        align-items: center;
        .button {
          display: flex;
          align-items: center;
          .ant-btn {
            width: 110px;
            margin-left: 16px;
          }
        }
      }
    }
    .table {
      margin: 16px 0;
    }
  }
}
.editclasses-model{
  .ant-form-item {
    margin-bottom: 32px;
    .ant-form-explain {
      position: absolute;
      top: 55px;
    }
  }
}
</style>
